function text(ctx, text, x, y, fontSize, fontColor) {
  ctx.font = "bold " + fontSize + " Arial";

  ctx.fillStyle = fontColor;

  ctx.fillText(text, x, y);
}

function wrapText(
  context,
  text,
  x,
  y,
  maxWidth,
  lineHeight,
  fontColor,
  fontSize
) {
  context.fillStyle = fontColor;

  if (text == "BLEND" || text == "INTERNAL TRANSFER")
    context.font = "bold " + fontSize + " Arial";
  else context.font = fontSize + " Arial";
  const words = text;
  let line = "";
  for (let n = 0; n < 1; n++) {
    let testLine = `${line + words} `;
    let metrics = context.measureText(testLine);
    let testWidth = metrics.width;
    if (testWidth > maxWidth && n > 0) {
      context.fillText(line, x, y);

      line = `${words} `;
      y += lineHeight;
    } else {
      line = testLine;
    }
  }
  context.fillText(line, x, y);
}

function roundRect(context, x, y, width, height, radius, fill, stroke) {
  let header_height = 55;
  if (typeof stroke === "undefined") {
    stroke = true;
  }
  if (typeof radius === "undefined") {
    radius = 5;
  }
  if (typeof radius === "number") {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  } else {
    let defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
    for (let side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }

  // Heading RECT
  context.beginPath();
  context.moveTo(x + radius.tl, y);
  context.lineTo(x + width - radius.tr, y);

  context.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  context.lineTo(x + width, y + header_height);
  context.lineTo(x, y + header_height);
  context.lineTo(x, y + radius.tr);
  context.quadraticCurveTo(x, y, x + radius.tl, y);
  context.closePath();
  if (fill) {
    context.fill();
  }
  // Content RECT

  context.beginPath();
  context.moveTo(x, y + header_height);
  context.lineTo(x + width, y + header_height);
  context.lineTo(x + width, y + height - radius.br);
  context.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius.br,
    y + height
  );
  context.lineTo(x + radius.bl, y + height);
  context.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  context.lineTo(x, y + header_height);

  // if (stroke) {
  //   context.strokeStyle = context.fillStyle;
  //   context.stroke();
  // }
  if (fill) {
    context.fillStyle = "#ffffff";
    context.fill();
  }
}

// function roundRect(context, x, y, width, height, radius, fill, stroke) {
//   if (typeof stroke === "undefined") {
//     stroke = true;
//   }
//   if (typeof radius === "undefined") {
//     radius = 5;
//   }
//   if (typeof radius === "number") {
//     radius = { tl: radius, tr: radius, br: radius, bl: radius };
//   } else {
//     let defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
//     for (let side in defaultRadius) {
//       radius[side] = radius[side] || defaultRadius[side];
//     }
//   }
//   context.beginPath();
//   context.moveTo(x + radius.tl, y);
//   context.lineTo(x + width - radius.tr, y);

//   context.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
//   context.lineTo(x + width, y + height - radius.br);
//   context.quadraticCurveTo(
//     x + width,
//     y + height,
//     x + width - radius.br,
//     y + height
//   );
//   context.lineTo(x + radius.bl, y + height);
//   context.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
//   context.lineTo(x, y + radius.tl);
//   context.quadraticCurveTo(x, y, x + radius.tl, y);

//   context.closePath();
//   if (fill) {
//     context.fill();
//   }
//   if (stroke) {
//     context.stroke();
//   }
// }

export default {
  text,
  wrapText,
  roundRect,
};
