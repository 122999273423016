import axios from "axios";

export default {
  async download(batch_no) {
    return await axios
      .post(
        `report/batch-track/`,
        { batch: batch_no },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        console.log("RES", res);
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${batch_no}-track.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },

  async BatchTrackChart(batch_no) {
    return await axios
      .post(`report/batch-track-chart/`, { batch: batch_no })
      .then((res) => {
        console.log("RES", res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },

  async downloadPoDetails(fromDate, toDate, ptype) {
    let date_time = new Date();
    date_time = `${(date_time.getMonth() + 1).toString().padStart(2, "0")}/${date_time.getDate().toString().padStart(2, "0")}/${date_time
      .getFullYear()
      .toString()}`;
    let fileName = `PO Details-` + date_time.replaceAll(`/`, `-`);

    return await axios({
      method: "get",
      url: `report/po-details`,
      params: {
        fromDate,
        toDate,
        ptype,
      },
      responseType: "blob",
    })
      .then((res) => {
        console.log("RES", res);
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },

};
