export function generateOrgChartData(d, depth) {
  // let { purchase, transfer, blend, historyData } = d;

  // const data = {
  //   name: "Lao Lao",
  //   title: "general manager",
  //   children: [],

  // };
  // ///////

  let child = [];

  d.transfer.forEach((element) => {
    console.log(element);
    child.push({
      batch_no: element.batch_no,
      purchase_type: element.purchase_type,
      ref_number: element.ref_number,
      remark: element.remark,
      children: element.children,
      date: element.date,
    });
  });
  // d.purchase.forEach((element) => {
  //   child.push({
  //     name: element.batch_no,
  //     title: element.purchase_type,
  //     ref_number: element.ref_number,
  //     remark: element.remark,
  //   });
  // });

  d.blend.forEach((element) => {
    child.push({
      batch_no: element.batch_no,
      purchase_type: element.purchase_type,
      ref_number: element.ref_number,
      remark: element.remark,
      date: element.date,
    });
  });

  console.log(d);
  let reverseArray = d.historyData.reverse();

  reverseArray.forEach((element, idx) => {
    if (idx < reverseArray.length - 1)
      element.children = [d.historyData[idx + 1]];
    else element.children = child;
  });
  const data = reverseArray[0];
  console.log("dastaaa", JSON.stringify(reverseArray[0]));
  // //////////
  // d.historyData.forEach((element) => {
  //   data["children"].push({
  //     name: element.batch_no,
  //     title: element.purchase_type,
  //     ref_number: element.ref_number,
  //     remark: element.remark,
  //   });
  // });
  // //////////
  // for (let i = 0; i < 3; i++) {
  //   data["children"].push({
  //     name: "Lao Lao",
  //     title: "general manager",
  //     children: [
  //       { name: "Bo Miao", title: "department manager" },
  //       {
  //         name: "Su Miao",
  //         title: "department manager",
  //         children: [{ name: "Tie Hua", title: "senior engineer" }],
  //       },
  //     ],
  //   });
  // }
  // let temp = data;
  // for (let i = 0; i < depth; i++) {
  //   if (!temp.children) {
  //     temp.children = [];
  //   }
  //   temp.children.push({
  //     name: "Lao Lao",
  //     title: "general manager",
  //     children: [
  //       { name: "Bo Miao", title: "department manager" },
  //       {
  //         name: "Su Miao",
  //         title: "department manager",
  //         children: [
  //           { name: "Tie Hua", title: "senior engineer" },
  //           {
  //             name: "Hei Hei",
  //             title: "senior engineer",
  //             children: [{ name: "Xiang Xiang", title: "UE engineer" }],
  //           },
  //         ],
  //       },
  //     ],
  //   });
  //   temp = temp.children[0];
  // }
  console.log("DATTA", data);
  return data;
}

export function generateOrgChartDataFolded(depth, foldDepth) {
  const data = this.generateOrgChartData(depth);
  let tempNode = data;
  for (let i = 0; i < foldDepth && tempNode.children; i++) {
    tempNode = tempNode.children[0];
  }
  tempNode._children = tempNode.children;
  tempNode.children = null;
  return data;
}
