<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <v-card-title>Batch Number Crawler</v-card-title>
            <v-card-text class="px-0 py-0">
              <v-row class="ma-5">
                <v-col>
                  <label class="text-md text-typo font-weight-bolder ms-1"
                    ><span class="red--text"><strong>* </strong></span>Batch
                    No</label
                  >
                  <v-form ref="frm">
                    <v-text-field
                      v-model="batch_no"
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-2
                      "
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      placeholder="Enter Batch No"
                      :rules="[(v) => !!v || 'Batch No is required']"
                      persistent-hint
                    ></v-text-field> </v-form
                ></v-col>
                <v-col
                  ><v-btn
                    @click="clearFilter"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-secondary
                      bg-light
                      py-3
                      px-6
                      mr-2
                      mt-8
                    "
                    >Clear</v-btn
                  >

                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                      mr-2
                      mt-8
                    "
                    @click="ShowChart"
                    >Show</v-btn
                  >
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    color="blue-grey"
                    class="text-capitalize btn-ls btn-primary py-3 px-6 mt-8"
                    @click="download"
                    >Download
                    <v-icon size="20" class="text-white ml-2">
                      fas fa-cloud-download-alt
                    </v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <div id="org-chart-container" v-show="show_tree">
            <div class="menu-container">
              <v-layout row>
                <v-btn @click="bigger()">+</v-btn>
                <v-btn @click="smaller()">-</v-btn>
              </v-layout>
              <!-- 
        <v-card>
          <v-card-title>
            <div slot="header" class="action-title">
              <span>Support actions</span>
            </div>
            <div
              v-for="action in supportActions"
              :key="action"
              class="action-item"
            >
              {{ "* " + action }}
            </div>
          </v-card-title>
        </v-card> -->
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import OrgChart from "./org-chart";
import api from "../api";
import { generateOrgChartData } from "./data";
export default {
  name: "org-chart",
  data() {
    return {
      show_tree: false,
      batch_no: "",
      data: null,
      orgChart: null,
      supportActions: ["click node to toggle", "drag canvas"],
    };
  },
  created() {},
  mounted() {
    this.orgChart = new OrgChart();
  },
  methods: {
    async download() {
      if (this.validate()) {
        this.overlay = true;
        await api.download(this.batch_no);
        this.overlay = false;
      }
    },
    async ShowChart() {
      this.show_tree = false;

      if (this.validate()) {
        let res = await api.BatchTrackChart(this.batch_no);
        console.log("RESPONSE", res);
        let data = {
          purchase: res.purchase,
          transfer: res.transfer,
          blend: res.blend,
          historyData: res.historyData,
        };
        if (
          data.purchase.length == 0 &&
          data.transfer.length == 0 &&
          data.blend.length == 0 &&
          !data.historyData
        ) {
          this.show_tree = false;
          this.showNoDataAlert("NO Record Found.Please Try another Batch no.");
        } else {
          this.show_tree = true;
          this.data = generateOrgChartData(data, 10);
          console.log("DATTA", this.data);

          this.orgChart.draw(this.data);
        }
        // let data = {
        //   purchase,
        //   transfer,
        //   blend,
        //   historyData,
        // };
      }
    },
    clearFilter() {
      this.$refs.frm.reset();
      this.$refs.frm.resetValidation();
    },
    resetValidation() {
      this.$refs.frm.resetValidation();
    },
    test() {
      this.orgChart.draw(this.data);
    },
    bigger() {
      this.orgChart.bigger();
    },
    smaller() {
      this.orgChart.smaller();
    },
    showNoDataAlert(message) {
      this.clearFilter();
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
    validate() {
      return this.$refs.frm.validate();
    },
  },
};
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.orgChart {
  border: 1px solid black;
  height: 100%;
}
.menu-container {
  position: absolute;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 156px;
  display: flex;
  align-items: center;
  flex-direction: column;
  vertical-align: top;
  width: 200px;
  height: 100%;
  background-color: rgba(238, 237, 236, 0.5);
}
.action-title {
  font-size: 28px;
}
.action-item {
  font-size: 24px;
}
.tree-node {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: antiquewhite;
  text-align: center;
  line-height: 28px;
}
</style>
